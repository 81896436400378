import { btn, mainView, color, mainTitle, mainSubtitle } from '../common/common.styles';

const page1Styles = {
    mainView: { ...mainView },
    mainTitle: { ...mainTitle },
    mainSubtitle: { ...mainSubtitle },
    picture: {
        position: 'relative',
        right: '30px',
        top: '30px'
    },
    btn: {
        ...btn,
        backgroundColor: color,
        color: 'fff',
        marginBottom: '3.2rem',
        marginTop: '3.2rem',
        position: 'relative'
    }
};

export default page1Styles;