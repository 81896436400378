import { users, target, speaker, rocket, law, stats, chart, background } from '../../assets/img/page4';
import { btn, mainView, color, mainTitle, mainSubtitle } from '../common/common.styles';

const icon = {
    backgroundRepeat: 'no-repeat',
    width: '6rem',
    marginTop: '10px'
};

const page4Styles = {
    mainBackground: {
        backgroundImage: "url(" + background + ")",
        backgroundRepeat: 'round',
        backgroundAttachment: 'fixed'
    },
    opacityBackground: {
        display: 'inline-table',
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    mainView: {...mainView },
    mainTitle: {...mainTitle },
    subtitle: {...mainSubtitle, marginBottom: '40px' },
    flex: {
        display: 'flex',
        flexDirection: 'column'
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '10% 85%',
        marginBottom: '27px'
    },
    iconUsers: {
        backgroundImage: "url(" + users + ")",
        ...icon
    },
    iconTarget: {
        backgroundImage: "url(" + target + ")",
        ...icon
    },
    iconSpeaker: {
        backgroundImage: "url(" + speaker + ")",
        ...icon
    },
    iconRocket: {
        backgroundImage: "url(" + rocket + ")",
        ...icon
    },
    iconLaw: {
        backgroundImage: "url(" + law + ")",
        ...icon
    },
    iconStats: {
        backgroundImage: "url(" + stats + ")",
        ...icon
    },
    iconChart: {
        backgroundImage: "url(" + chart + ")",
        ...icon
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '22px'
    },
    containerTitle: {
        marginBottom: '5px',
        fontSize: '19px',
        lineHeight: '24px',
        fontWeight: 'bold',
    },
    containerText: {
        fontSize: '15.5px',
        marginBottom: '0px',
        lineHeight: '18px',
        letterSpacing: '-0.015em'
    },
    btn: {
        backgroundColor: color,
        color: 'fff',
        ...btn,
        marginTop: '2.7rem'
    },
    btnInverted: {
        backgroundColor: '#fff',
        color: color,
        ...btn,
        marginBottom: '3.2rem'
    }
};

export default page4Styles;