import React, {useState } from 'react';
import { deviceDetect } from 'react-device-detect';
import Page1 from './components/page1/page1';
import Page2 from './components/page2/page2';
import Page3 from './components/page3/page3';
import Page4 from './components/page4/page4';
import Page5 from './components/page5/page5';
import Page6 from './components/page6/page6';
import './App.css';


const renderActiveView = (activeView, props) => {
  const View = activeView;
  return <View {...props}/>
}

function App() {
  const [views] = useState([Page1, Page2, Page3, Page4, Page5, Page6])
  const [activeView, setActiveView] = useState(0);
  const productInfo = useState({});

  const deviceInfo = deviceDetect();
  console.log(deviceInfo);

  return  (
    <div className="App">
      <header className="App-header">
         { renderActiveView(views[activeView], {productInfo, setActiveView}) }
      </header>
    </div>
  ) 
}

export default App;
