const btn = {
    fontWeight: 'bold',
    fontSize: '20px',
    width: window.screen.width - 60,
    borderRadius: 25,
    height: 55,
    marginBottom: 10
};

const mainView = {
    margin: '0rem 1.8rem',
    letterSpacing: 1.5,
    marginTop: '3.5rem'
};

const color = '#CBA65E';

const mainTitle = {
    marginBottom: '20px',
    fontWeight: 'bold',
    fontFamily: 'Bambino,Helvetica,Arial,sans-serif',
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '-0.011em',
    color: '#FFFFFF'
};

const mainSubtitle = {
    fontSize: '19px',
    lineHeight: '26px',
    letterSpacing: '-0.017em',
    color: '#FFFFFF',
    paddingRight: '30px'
};

export { btn, mainView, color, mainTitle, mainSubtitle };