import React from 'react';
import page2Styles from './page2.styles';
import YouTube from 'react-youtube';

const opts = {
	height: window.screen.height,
	width: window.screen.width,
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 0,
		controls: 0,
	},
};

const onReady = (event) => {
	// access to player in all event handlers via event.target
	event.target.playVideo();
};

const Page2 = (props) => {
	const onStateChange = (state) => {
		if (state.data === 0) props.setActiveView(2);
	};

	return (
		<div style={page2Styles.mainView}>
			<div style={page2Styles.containerVideo}>
				<YouTube
					videoId='xHJvBgq13kA'
					opts={opts}
					onReady={onReady}
					onStateChange={onStateChange}
				/>
			</div>
			<p
				style={page2Styles.text}
				onClick={() => {
					props.setActiveView(2);
				}}
			>
				Skip
			</p>
		</div>
	);
};

export default Page2;
