import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import box from '../../assets/img/page3/box.png';
import page3Styles from './page3.styles';

const Page3 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div style={page3Styles.mainView}>
            <h3 style={page3Styles.mainTitle}> HOW  <br /> IT WORKS </h3>
            <p style={page3Styles.subtitle}>HumidifGroup smart packaging solution combines NFC tags, our software platform and 30 years experience designing and producing packaging products.</p>

            <div style={page3Styles.flex}>
                <div style={page3Styles.container1}>
                    {<img style={page3Styles.image} src={box} alt="box" />}
                    <p style={page3Styles.titleContainer}> Enable product packaging and labels </p>
                    <p style={page3Styles.subtitleContainer}>Special NFC tags are included in our packaging creations, offering <i>ad hoc</i> and creative solutions.</p>
                </div>

                <div style={page3Styles.container2}>
                    <p style={page3Styles.titleContainer}> Digital experiences </p>
                    <p style={page3Styles.subtitleContainer}>Our clients engage their customers across mobile devices with web dynamic experiences made in the HGStudio.</p>
                </div>

                <div style={page3Styles.container3}>
                    <p style={page3Styles.titleContainer}> Software </p>
                    <p style={page3Styles.subtitleContainer}>HGtag@ platform connects our smart products to digital solutions.</p>
                </div>

                <div style={page3Styles.container4}>
                    <p style={page3Styles.titleContainer}> Back office dashboard </p>
                    <p style={page3Styles.subtitleContainer}> Real time analytics to measure pre and post sale actions, and assign content by product, location, time, device and more.</p>
                </div>

                <Button style={page3Styles.btn} onClick={() => { props.setActiveView(3) }}>  See benefits  </Button>
                <Button style={page3Styles.btnInverted} onClick={() => window.open("http://www.humidifgroup.com/#contact")}> Contact </Button>
            </div>
        </div>
    )
}

export default (Page3);