import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import page4Styles from './page4.styles';

const Page4 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div style={page4Styles.mainBackground}>
            <div style={page4Styles.opacityBackground}>
                <div style={page4Styles.mainView}>
                    <h3 style={page4Styles.mainTitle}>BENEFITS </h3>
                    <p style={page4Styles.subtitle}>Bringing companies and customers together.</p>

                    <div style={page4Styles.flex}>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconUsers} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Connect </p>
                                <p style={page4Styles.containerText}>Create a non-disruptive media channel from existing from-factors. </p>
                            </div>
                        </div>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconTarget} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Engage </p>
                                <p style={page4Styles.containerText}>Improve the customer experience by delivering personalized content. Extend your relationship beyond the sale and offer loyal buyers more. </p>
                            </div>
                        </div>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconSpeaker} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Inform </p>
                                <p style={page4Styles.containerText}>Tell your brand and product story from store to home. </p>
                            </div>
                        </div>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconRocket} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Differentiate your brand </p>
                                <p style={page4Styles.containerText}>Stand out on crowded shelves and connect with consumers through smart packaging. </p>
                            </div>
                        </div>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconLaw} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Law and regultaions </p>
                                <p style={page4Styles.containerText}>Avoid the noise and regulation of traditional channels. </p>
                            </div>
                        </div>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconStats} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Increase sales and lifetime value </p>
                                <p style={page4Styles.containerText}>Create a brand platform to grow post-purchase engagement and encourage direct sales. </p>
                            </div>
                        </div>

                        <div style={page4Styles.grid}>
                            <section style={page4Styles.iconChart} />
                            <div style={page4Styles.textContainer}>
                                <p style={page4Styles.containerTitle}> Analyze </p>
                                <p style={page4Styles.containerText}>Measure customer engagement before and after purchase. </p>
                            </div>
                        </div>
                        <Button style={page4Styles.btn} onClick={() => { props.setActiveView(4) }}>  See applications   </Button>
                        <Button style={page4Styles.btnInverted} onClick={() => window.open("http://www.humidifgroup.com/#contact")}> Contact </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default (Page4);