import React from 'react';
import { Button } from 'reactstrap';
import smartCloud from '../../assets/img/page1/smartCloud.png';
import page1Styles from './page1.styles';

const Page1 = (props) => {

    return (
        <div style={page1Styles.mainView}>
            <h3 style={page1Styles.mainTitle}> SMART PACKAGING <br/> by HUMIDIF GROUP  </h3>
            <p style={page1Styles.mainSubtitle}>We create the physical products and a digital platform together, incorporating a NFC tag in our packagings.</p>
            {<img style={page1Styles.picture} src={smartCloud} alt="smartCloud" />}

            <Button style={page1Styles.btn} onClick={() => { props.setActiveView(1) }}> Learn more </Button>
        </div>
    )
}
export default Page1;