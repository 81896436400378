import { Background, Background2, Background3, Background4 } from '../../assets/img/page3';
import { btn, mainView, color, mainTitle, mainSubtitle } from '../common/common.styles';

const container = {
    display: 'flex',
    flexDirection: 'column',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '28px',
    marginBottom: '25px',
    borderRadius: '25px'
}

const page3Styles = {
    mainView: {...mainView },
    mainTitle: { ...mainTitle },
    subtitle: { ...mainSubtitle, marginBottom: '3.7rem' },
    flex: { display: 'flex', flexDirection: 'column' },
    container1: { background: `url(${Background})`, ...container, },
    container2: { background: `url(${Background2})`, ...container, },
    container3: { background: `url(${Background3})`, ...container, },
    container4: { background: `url(${Background4})`, ...container, },
    image: {
        width: '120px',
        height: '50px',
        marginBottom: '20px',

    },
    titleContainer: {
        fontSize: '19px',
        lineHeight: '24px',
        fontWeight: 'bold',
    },
    subtitleContainer: {
        fontSize: '15.5px',
        marginBottom: '0px',
        lineHeight: '1.2',
        letterSpacing: '-0.015em'
    },
    btn: {
        backgroundColor: color,
        color: 'fff',
        ...btn,
        marginTop: '1.5rem'
    },
    btnInverted: {
        backgroundColor: '#fff',
        color: color,
        ...btn,
        marginBottom: '3.2rem'
    }
};

export default page3Styles;