import {
    Component1, Component2, Component3, Component4, Component5, Component6, Component7, Component8, Component9,
    Component10, Component11, Component12, Component13, Component14, Component15
} from '../../assets/img/page5';
import { btn, mainView, color, mainTitle } from '../common/common.styles';

const iconBox = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '70px',
    height: '70px',
    marginBottom: '1rem',
};

const page5Styles = {
    mainView: {
        ...mainView,
        marginBottom: '3.1rem',
    },
    mainTitle: { 
        ...mainTitle,
        marginBottom: '3rem',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumns: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '25px',
        padding: '7px'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon1: { background: `url(${Component1})`, ...iconBox },
    icon2: { background: `url(${Component2})`, ...iconBox },
    icon3: { background: `url(${Component3})`, ...iconBox },
    icon4: { background: `url(${Component4})`, ...iconBox },
    icon5: { background: `url(${Component5})`, ...iconBox },
    icon6: { background: `url(${Component6})`, ...iconBox },
    icon7: { background: `url(${Component7})`, ...iconBox },
    icon8: { background: `url(${Component8})`, ...iconBox },
    icon9: { background: `url(${Component9})`, ...iconBox },
    icon10: { background: `url(${Component10})`, ...iconBox },
    icon11: { background: `url(${Component11})`, ...iconBox },
    icon12: { background: `url(${Component12})`, ...iconBox },
    icon13: { background: `url(${Component13})`, ...iconBox },
    icon14: { background: `url(${Component14})`, ...iconBox },
    icon15: { background: `url(${Component15})`, ...iconBox },
    text: {
        textAlign: 'center',
        fontSize: '14px',
        marginBottom: '0px',
        lineHeight: '125%',
        letterSpacing: '0.06em'
    },
    btn: {
        backgroundColor: color,
        color: 'fff',
        ...btn,
        marginTop: '2.2rem'
    },
    btnInverted: {
        backgroundColor: '#fff',
        color: color,
        ...btn
    }
};

export default page5Styles;