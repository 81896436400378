import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import page5Styles from './page5.styles';

const Page5 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div style={page5Styles.mainView}>

            <h3 style={page5Styles.mainTitle}>APPLICATIONS <br /> AND EXPERIENCES </h3>

            <div style={page5Styles.flex}>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon1}></div>
                    </div>
                    <p style={page5Styles.text}>Product verification </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon2}></div>
                    </div>
                    <p style={page5Styles.text}>First opening confirmation </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon3}></div>
                    </div>
                    <p style={page5Styles.text}>Related products </p>
                </div>
            </div>

            <div style={page5Styles.flex}>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon4}></div>
                    </div>
                    <p style={page5Styles.text}>Smoke location </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon5}></div>
                    </div>
                    <p style={page5Styles.text}>Interactive content </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon6}></div>
                    </div>
                    <p style={page5Styles.text}>Exclusive offers </p>
                </div>
            </div>

            <div style={page5Styles.flex}>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon7}></div>
                    </div>
                    <p style={page5Styles.text}>Loyal buyer benefits </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon8}></div>
                    </div>
                    <p style={page5Styles.text}>Product suggestions </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon9}></div>
                    </div>
                    <p style={page5Styles.text}>Feedback and reviews </p>
                </div>
            </div>

            <div style={page5Styles.flex}>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon10}></div>
                    </div>
                    <p style={page5Styles.text}>How-to guides </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon11}></div>
                    </div>
                    <p style={page5Styles.text}>Care and service info </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon12}></div>
                    </div>
                    <p style={page5Styles.text}>Tap to re-order </p>
                </div>
            </div>

            <div style={page5Styles.flex}>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon13}></div>
                    </div>
                    <p style={page5Styles.text}>Social Media </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon14}></div>
                    </div>
                    <p style={page5Styles.text}>FDA verification </p>
                </div>
                <div style={page5Styles.flexColumns}>
                    <div style={page5Styles.iconContainer}>
                        <div style={page5Styles.icon15}></div>
                    </div>
                    <p style={page5Styles.text}>Company news</p>
                </div>

            </div>
            <Button style={page5Styles.btn} onClick={() => { props.setActiveView(5) }}>  See more  </Button>
            <Button style={page5Styles.btnInverted} onClick={() => window.open("http://www.humidifgroup.com/#contact")}> Contact </Button>
        </div>
    )
}
export default (Page5);