import { color } from '../common/common.styles';

const page2Styles = {
    mainView: {
        height: '100vh',
        width: '100vw'
    },
    containerVideo: {
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
    },
    text: {
        color: color,
        textAlign: 'right',
        position: 'absolute',
        top: '90%',
        right: '45%',
        letterSpacing: '2',
        zIndex: 10
    }
};

export default page2Styles;