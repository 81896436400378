import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import ready from '../../assets/img/page6/ready.png';
import page6Styles from './page6.styles';

const Page6 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div style={page6Styles.mainView}>
            <h3 style={page6Styles.mainTitle}> READY FOR YOUR SMART PACKAGING INITIATIVE ?  </h3>
            <p style={page6Styles.mainSubtitle}>Our fully integrated connected packaging solution provides brand protection and turns products into a platform to directly engage consumers.</p>
            {<img style={page6Styles.picture} src={ready} alt="ready" />}

            <Button style={page6Styles.btn} onClick={() => window.open("http://www.humidifgroup.com/#contact")}> Contact us to learn more </Button>
            <Button style={page6Styles.btnInverted} onClick={() => { props.setActiveView(0) }}> Review experience </Button>
        </div>
    )
}
export default (Page6);