import { btn, mainView, color, mainTitle, mainSubtitle } from '../common/common.styles';

const page6Styles = {
    mainView: { ...mainView },
    mainTitle: { ...mainTitle },
    mainSubtitle: { ...mainSubtitle },
    picture: { marginTop: '3.2rem' },
    btn: {
        ...btn,
        backgroundColor: color,
        color: 'fff',
        marginTop: '3.2rem'
    },
    btnInverted: {
        backgroundColor: '#fff',
        color: color,
        ...btn,
        marginBottom: '3.2rem'
    }
};

export default page6Styles;